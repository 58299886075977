import React from "react";
import "./App.css";
import "./assets/iconfont/iconfont.css";
import { useAuth } from "./context/auth-context";
import { AuthenticatedApp } from "./authenticated-app";
import { Book } from "./book";
import { UnauthenticatedApp } from "./unauthenticated-app";
import { ErrorBoundary } from "./components/error-boundary";
import { FullPageErrorFallback } from "./components/lib";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Jdsd } from "./jdsd";
import { JdsdSlow } from "./jdsdslow";
import { Navigate } from "react-router";
import { Register } from "./register/register";
import { WxRegister } from "./register/wxregister";
import { WxResetPWD } from "./register/wxresetpsw";
import { Register_Null } from "./register/register_null";
import { ResetPWD_Null } from "./register/resetpwd_null";
import { WxURLScheme } from "./register/wxurlscheme";
import { NavTree } from "./tree";

function App() {
  const { user } = useAuth();
  return (
    <ErrorBoundary fallbackRender={FullPageErrorFallback}>
      <BrowserRouter>
        <Routes>
          {/*<Route path={"/"} element={user ? <AuthenticatedApp /> : <UnauthenticatedApp />} />*/}
          <Route path={"/"} element={<NavTree />} />
          {/*<Route path={"/jdsd"} element={<Jdsd/>} />*/}
          <Route path={"/login"} element={<UnauthenticatedApp />} />
          {/*<Route path={"/jdsdSlow"} element={<JdsdSlow/>} />*/}
          <Route path={"/books"}>
            <Route path={":bookId"} element={<Book />} />
            <Route path={""} element={<Navigate to="/" />} />
          </Route>
          <Route path={"/courses"}>
            <Route path={":courseId"} element={<Jdsd />} />
            <Route path={""} element={<Navigate to="/" />} />
          </Route>
          <Route path={"/register"}>
            <Route path={":registerId"} element={<Register />} />
            <Route path={""} element={<Register_Null />} />
          </Route>
          <Route path={"/wxregister"}>
            <Route path={":registerId"} element={<WxRegister />} />
            <Route path={""} element={<Register_Null />} />
          </Route>
          <Route path={"/wxresetpwd"}>
            <Route path={":registerId"} element={<WxResetPWD />} />
            <Route path={""} element={<ResetPWD_Null />} />
          </Route>
          <Route path={"/wxurl"}>
            <Route path={":id"} element={<WxURLScheme />} />
            <Route path={""} element={<AuthenticatedApp />} />
          </Route>
          <Route path={"/nav"} element={<NavTree />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/*{user ? <AuthenticatedApp /> : <UnauthenticatedApp />}*/}
        {/*<Jdsd />*/}
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
